.l-footer {
  padding: 45px 0;
  background: $c-gray;

  &__logo {
    margin-bottom:40px;
  }

  &__title {
    @include f(17px,$c-white,700);
    margin-bottom:0;
  }

  &__caption {
    position: relative;
    margin-bottom:10px;
    @include f(17px,$c-white);
    font-family:$ff-slab;
  }

  &__column {

  }

  &__link {
    a {
      @include f(21px,$c-white,700,0,0,$ff-slab);
    }
  }
}
