.slider {
  
  &__inner {
    position: relative;

    .prev, .next {
      display: inline-block;
      position: absolute;
      top: calc(50% - 25px);
      @include size(50px);
      cursor: pointer;
    }
    .prev {
      left: -80px;
    }
    .next {
      right: -80px;
    }
  }
  
  .slide {
    background-position: center;
    background-size: cover;
    &__image {
      margin-bottom:60px;
      img {
        width:auto;
      }
      @include mq($screen-md) {
        margin-bottom:30px;
      }
    }

    &__content {
      padding:75px 0 150px 0;
      @include mq($screen-md) {
        padding:80px 0 60px;
      }
    }

    &__box {
      background-color: $c-theme;
      padding:20px 40px;
      display: inline-block;
      span {
        font-weight: 700;
      }
      @include mq($screen-md) {
        padding:10px 20px;
      }
    }

    &__title {
      @include f(34px,$c-white,400,1.1em,0,$ff-slab);
      margin-bottom:15px;
    }

    &__text {
      @include f(19px,$c-white,400,1.4em,0,$ff-slab);
      margin-bottom:20px;
    }

    &__link {
      @include f(15px,$c-white,700,0,uppercase);
    }
    @include mq($screen-md) {
      &__title {
        @include f(25px);
        margin-bottom:10px;
      }
      &__text {
        @include f(15px);
        margin-bottom:10px;
      }
    }
  }

  .owl-dots {
    position: absolute;
    bottom: 80px;
    left:50%;
    transform: translateX(-50%);

    .owl-dot span {
      transition: all .3s ease;
      @include size(13px);
      border: none;
      border-radius: 50%;
      margin: 0 5px;
      background: $c-white;
    }

    .owl-dot span:hover,
    .owl-dot.active span {
      background: $c-gray;
    }
    @include mq($screen-md) {
      bottom:30px;
    }
  }

}