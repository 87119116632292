// config
// --------------------------------------------------

	$img: 	'../img/site';
	$icons: '../img/site/icons';
	$fonts: '../fonts';

	$ff-default: "Roboto";
	$ff-slab: "Roboto Slab";
	$default-font-size: 14px;


// colors
// --------------------------------------------------

	$c-font: #3e3e3e; // default font color
	$c-theme: #e22531;
	$c-border: #ebebeb;

	$c-white: #fff;
	$c-black: #000;
	$c-gray: #575656;
	$c-top: #c8c8c8;

	$c-success: hsl(145, 63%, 40%);
	$c-danger:	#E26867;
	$c-warning: hsl(32, 97%, 46%);
	$c-info:    hsl(225, 46%, 42%);

	$c-facebook: #385895;
	$c-linkedin: #0077b5;
	$c-twitter: #1da1f2;
	$c-instagram: #ad69b0;
	$c-google-plus: #de4e43;
	$c-youtube: #e62117;
	$c-pinterest: #bd081c;



// media queries
// --------------------------------------------------

	$screen-xxlg: 1799px;
	$screen-xlg: 	1499px;
	$screen-lg: 	1199px;
	$screen-md: 	991px;
	$screen-sm: 	767px;

	$screen-sidr: $screen-md;


// z-index
// --------------------------------------------------

	$z-index-cookies: 		9000;
	$z-index-sidr-toggle: 8999;
	$z-index-sidr: 				8998;
	$z-index-pace: 				8997;
	$z-index-social: 			1039; // below lightgallery (1040)

