.p-contract {
  .text {
    @include f(17px);

    &--bg {
      color:$c-theme;
      font-weight:700;
    }
    @include mq($screen-sm) {
      @include f(15px);
    }
  }

  &__logos {
    margin-top:40px;
    img {
      margin-bottom:15px;
    }
  }
}