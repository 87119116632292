.l-newsletter {
  background-color: $c-theme;
  padding-top:70px;

  header {
    margin-bottom:30px;
    h2 {
      @include f(21px,$c-white,0,0,0,$ff-slab);
      span {
        font-weight:700;
      }
      @include mq($screen-sm) {
        @include f(18px)
      }
    }
  }

  &__input {
    width:50%;
    margin:auto;
    @include mq($screen-md) {
      width:80%;
    }
    @include mq($screen-sm) {
      width:100%;
      text-align: center;
    }
  }
}