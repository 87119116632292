.header {
	background-color: $c-gray;

  &__right {
  	text-align: right;
  }

  .logo {
  	@include mq($screen-sidr) {
			padding: 15px 0;
			text-align: center;

			img {
				height: 50px;
			}
  	}
  }

}