.c-box {
  transition: all .25s ease;
  @include flexAlign();
  margin-bottom:30px;

  &__content {
    text-align: center;
    padding:0 18%;
  }

  &__text {
    @include f(21px,$c-top,400,1.5em,uppercase,$ff-slab);
    a {
      @include f(15px,$c-white,0,0,0,$ff-default);
    }
  }

  @include mq($screen-md) {
    &__text {
      @include f(17px)
    }
  }
  @include mq($screen-xs) {
    &__content {
      padding:0 5px;
    }
    &__text {
      &, a {

        @include f(12px)
      }
    }
  }
}