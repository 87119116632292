.form-standard {
  $_height: 45px;
  $_border: 1px solid $c-black;
  $_border-color-focus: $c-theme;
  $_border-radius: 25px;
  $_padding: 15px;
  $_font-size: 13px;
  $_color: $c-black;
  $_background: $c-white;

  .form-group {

    &--bootstrap-select {
      @include createBootstrapSelect($_height, $_border, $_border-radius, $_border-color-focus, $_padding, $_font-size, $_color, $_background);
    }

    &--select {
      select {
        -webkit-appearance: none;
      }

      position: relative;
      &::after {
        content: '\f078';
        font-family: "FontAwesome";
        position: absolute;
        bottom: calc(#{$_height} - 20px);
        @include size(20px);
        right: calc(#{$_height}*.4 - 2px);
        @include f(13px,$_color,0,$_height);
      }

    }

    &--number {
      position: relative;
      input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      .up, .down {
        position: absolute;
        right: 18px;
        bottom: 0;
        cursor: pointer;
        @include f(13px,$c-theme,0,20px);
        text-align: center;
        @include size(20px);
        transition: all 0.2s ease;

        &:hover,&:focus {
          background: $c-theme;
          color: $c-white;
        }
      }
      .up {
        transform: translateY(-100%);
        margin-bottom: 4px;
      }
      .down {
        margin-top: 2px;
      }
    }
  }

  label {
    display: inline-block;
    margin: 0 0 7px;
    @include f(14px,$c-font,500,0,uppercase);
  }

  input,
  textarea,
  select {
    transition: all 0.25s ease;
    height: $_height;
    border: $_border;
    border-radius: $_border-radius;
    padding: 0 $_padding;
    @include f($_font-size,$_color,700,$_height);
    @include placeholder($_color);
    font-style: italic;
    background: $_background;

    &:focus {
      border-color: $_border-color-focus;
    }
  }
  textarea {
    resize: none;
    height: 400px;
    padding: $_padding;
    line-height: initial;
    border-radius: 0;
  }

  input[type="submit"],
  button[type="submit"] {
    display: inline-block;
    @include size(initial);
    border: none;
    padding: 0 10px;
    @include f(15px,$c-white,400,0,uppercase);
    min-width:0;

    &:hover {
      
    }
    @include mq($screen-sm) {
      margin:auto;
    }
  }


  // .select-wrapper { position: relative; overflow: hidden; border: $_border; border-radius: $_border-radius;
  //   select { width: calc(100% + 30px); border: none; appearance: none; -webkit-appearance: none; }
  //   &:after { content: ""; position: absolute; top: ($_height - 8px)/2; right: ($_height * .4); @include triangle(down,8px,$_color); }
  // }
  @include createCheckboxes(16px, $_background, $_border, $c-white);
  // @include createBootstrapSelect($_height, $_border, $_border-radius, $_border-color-focus, $_padding, $_font-size, $_color, $_background);
  // @include createFileButton($_height, $_padding, $c-theme, 16px, $c-white);



  .required > label:after {
    content: " *";
  }

  .has-error {
    input,
    input[type="radio"] + label span,
    input[type="checkbox"] + label span,
    textarea,
    select {
      border-color: $c-danger;
    }

    input[type="radio"] + label,
    input[type="checkbox"] + label {
      color: $c-danger;
    }

    .form-error-desc {
      padding: 5px 15px;
      @include borderBottomRadius(4px);
      @include f(12px,$c-white,400);
      text-align: left;
      background: $c-danger;
    }
  }
}

.form-newsletter { 
  $_input-height: 45px;
  $_border: 0 solid transparent;
  $_border-radius: 0;
  $_color: $c-font;
  $_background: $c-white;

  position: relative;
  border-radius: $_border-radius;
  font-size: 0;

  input[type="text"],
  button[type="submit"] {
    border: $_border;
    @include f(14px,$_color,400,$_input-height);
    @include placeholder(#b4b4b4);
  }

  input[type="text"] {
    width: 100%;
    padding: 0 100px 0 10px;

    &:focus {
      border-color: $c-theme;
    }
  }
  @include createCheckboxes(16px, $_background, none, $c-white);
  button[type="submit"] {
    //position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    @include size(auto,$_input-height);
    border-width: 0;
    padding: 0 15px;
    @include f(14px,$c-white,400,$_input-height);
    text-align: left;
    white-space: nowrap;
    background-color: $c-theme;

    &:hover {
      opacity: .8;
    }
  }
}

.form-dropdown { //require uncomment bootstrap-select
  $_background: #ddd;
  $_color: #000;
  $_focus: red;

  .form-group {
    display: inline-block;
    margin-bottom: 0;
  }

  button.dropdown-toggle {
    background: transparent!important;
    outline: none!important;
    box-shadow: none!important;
    border: none!important;
    color: $_color!important;
    &:focus {
      background: transparent!important;
      outline: none!important;
      box-shadow: none!important;
    }
    .bs-caret {
      display: none;
    }

    position: relative;
    &::after {
      content: '\f107';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      font-family: "FontAwesome";
    }
  }

  .dropdown-menu {
    border-radius: 0;
    border: none;
    box-shadow: none;
    background: $_background;
    ul {
      li {
        a {
          line-height: normal;
          margin: 0;

          &:focus {
            outline: none!important;
          }
          &:hover,&:focus {
            background-color: $_focus;
          }
          span {
            margin:0;
          }
        }
      }
    }
  }
}