.c-btn {
	transition: all .25s ease .1s;
	display: inline-block;
	min-width: 125px;
	outline: none;
	box-shadow: none;
	margin: 0;
	border: none;
	padding: 0 15px;
	cursor: pointer;
	@include f(15px,$c-white,400,30px,uppercase);
	text-align: center;
	background: $c-theme;
	border-radius: 35px;

	&:hover, &:focus {
		color: $c-white;
		background: lighten($c-theme,7%);
	}
}
