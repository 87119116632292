.p-home {

  &__boxes {
    padding:0;
    margin: -30px 0 -60px;
    position: relative;
    z-index: 2;
    @include mq($screen-md) {
      margin: -15px 0 -45px;
    }
  }
}